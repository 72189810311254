import React from 'react';
import './Score.css';
import * as Types from './Types';
import * as LocalStorage from './LocalStorage';
import * as WelcomeDialog from './WelcomeDialog';

interface ScoreProps {
    scoreInformation: Types.ScoreInformation;
    yesterdaysWord: string | undefined;
}

export const Score = (props: ScoreProps): JSX.Element => {
    const { currentStreak } = props.scoreInformation;
    const streak = currentStreak >= 0 ? "Segersvit" : "Förlustsvit";
    const yesterdaysWord = props.yesterdaysWord !== undefined ? props.yesterdaysWord : "-" ;
    return (
        <div className="ScoreInfo">
            <p>Din nivå: <span className="ScoreTitle">{getAnimal(currentStreak)}</span></p>
            <p>{streak}: {Math.abs(currentStreak)} {days(currentStreak)}</p>
            <p>Gårdagens ord: {yesterdaysWord}</p>
        </div>
    );
}

interface ScoreDialogProps {
    show: boolean;
    onClose: any;
    scoreInformation: Types.ScoreInformation;
}

export const ScoreDialog = (props: ScoreDialogProps):JSX.Element => {
    if (props.show) {
        return (
            <dialog open>
                <ScoreDialogContent show={true} onClose={props.onClose} scoreInformation={props.scoreInformation}/>
            </dialog>
        );
    } else {
        return (
            <dialog>
            </dialog>
        );
    }
}

const ScoreDialogContent = (props: ScoreDialogProps):JSX.Element => {
    const si = props.scoreInformation;
    const streak = si.currentStreak >= 0 ? "Segersvit" : "Förlustsvit";
    return (
        <div>
            <h1>Wordle - Statistik</h1>
            <p>Din nivå: <span className="ScoreTitle">{getAnimal(si.currentStreak)}</span></p>
            <p>{streak}: {Math.abs(si.currentStreak)} {days(si.currentStreak)}</p>
            <p>Högsta uppnådda nivå: {getAnimal(si.longestStreak)} ({si.longestStreak} {days(si.longestStreak)})</p>
            <p>Spelat: {si.daysPlayed} {days(si.daysPlayed)}</p>
            <p>Klarat: {si.daysCorrect} {days(si.daysCorrect)}</p>
            <p>Misslyckats: {si.daysIncorrect} {days(si.daysIncorrect)}</p>
            <p>Ofärdiga: {si.daysNotFinished} {days(si.daysNotFinished)}</p>
            <form method="dialog">
                <button className="Button" onClick={() => {props.onClose()}}>Stäng</button>
            </form>
        </div>
    );
}

const days = (n: number): string => {
    if (Math.abs(n) === 1) {
        return "dag";
    } else {
        return "dagar";
    }
}

export const calcStoredScoreData = (isNewDay: boolean): Types.ScoreInformation => {
    const aallKeys = LocalStorage.getAllKeys()
    const allKeys = aallKeys.filter(key => key !== WelcomeDialog.localStorageKey);
    allKeys.sort();
    allKeys.reverse();
    let countingCurrentScore = true;
    let countingCurrentLoosingScore = true;
    let currentStreak = 0;
    let longestStreak = 0;
    let currentCountLongestStreak = 0;
    let daysCorrect = 0;
    let daysIncorrect = 0;
    let daysNotFinished = 0;
    for (let i = 0; i < allKeys.length; i++) {
        const stored = LocalStorage.getData(allKeys[i]) as Types.WordleStorage;
        const guesses: Types.Guess[] | undefined = stored?.guesses;
        if (guesses !== undefined && Array.isArray(guesses) && guesses.length > 0) {
            const lastGuess = guesses[guesses.length - 1];
            if (lastGuess?.letterFeedback !== undefined && Array.isArray(lastGuess.letterFeedback) &&
                lastGuess.letterFeedback.length > 0) {
                const allCorrect = lastGuess.letterFeedback.every((x: Types.Feedback) => {
                        return (x === "CorrectPlace");
                    });
                if (allCorrect === true) {
                    countingCurrentLoosingScore = false;
                    if (countingCurrentScore) {
                        currentStreak++;
                    }
                    currentCountLongestStreak += 1;
                    if (currentCountLongestStreak > longestStreak) {
                        longestStreak = currentCountLongestStreak;
                    }
                    daysCorrect++;
                } else {
                    if (!isNewDay && i === 0 && guesses.length < Types.numGuesses) { // It is OK to not yet have finished today's word.
                        // Do not change the value of countingCurrentScore to true, keep it as it is.
                    } else {
                        if (countingCurrentLoosingScore && !countingCurrentScore) {
                            currentStreak--;
                        }
                        countingCurrentScore = false;
                        if (guesses.length === Types.numGuesses) {
                            daysIncorrect++;
                        } else {
                            daysNotFinished++;
                        }
                    }
                    currentCountLongestStreak = 0;
                }
            }
        }
    }
    return { currentStreak
           , longestStreak
           , daysPlayed: allKeys.length
           , daysCorrect
           , daysIncorrect
           , daysNotFinished
           }
}

const skaning = [ "Fubbick"
                , "Ålahue"
                , "Ålarens"
                ]

const animals = [ "Amöba"
                , "Daggmask"
                , "Kackerlacka"
                , "Huggorm"
                , "Pungråtta"
                , "Grävling"
                , "Vessla"
                , "Iller"
                , "Fladdermus"
                , "Skata"
                , "Uggla"
                , "Örn"
                , "Hyena"
                , "Chihuahua"
                , "Räv"
                , "Neanderthalare"
                , "Sopåkare"
                , "Lagerarbetare"
                , "Socialassistent"
                , "Läkare"
                , "Börshaj"
                , "Raketingenjör"
                , "Arnold Schwarzenegger (IQ 135)"
                , "Madonna (IQ 140)"
                , "Napoleon Bonaparte (IQ 145)"
                , "Olof Palme (IQ 156)"
                , "Albert Einstein (IQ 160)"
                , "Wolfgang Amadeus Mozart (IQ 165)"
                , "Paul Allen (IQ 170)"
                , "Gallileo Galilei (IQ 180)"
                , "Marilyn Vos Savant (IQ 186)"
                , "Garry Kasparov (IQ 190)"
                , "Johann Wolfgang von Goethe (IQ 210)"
                , "William James Sidis (IQ 250+)"
                ]

const getAnimal = (numDays: number): string => {
    if (numDays >= 0) {
        const ix = Math.min(numDays, animals.length-1);
        return animals[ix];
    } else {
        const loosing_streak = Math.max(0, Math.abs(numDays)-1); // Remove one to start indexing from 0.
        const ix = Math.min(loosing_streak, skaning.length-1);
        return skaning[ix];
    }
}
