/**
 * Store an object in localStorage with the Key key.
 */
export const setData = (key: string, obj: Object): void => {
    localStorage.setItem(key, JSON.stringify(obj));
}

/**
 * Retrieve the object for a specific key.
 */
export const getData = (key: string): Object | undefined => {
    const value = localStorage.getItem(key);
    if (value) {
        return JSON.parse(value);
    } else {
        return undefined;
    }
}

/**
 * Remove a key and its value.
 */
export const removeItem = (key: string): void => {
    localStorage.removeItem(key);
}

/**
 * Remove all stored data.
 */
export const clearAll = (): void => {
    localStorage.clear();
}

/**
 * Get all keys which are stored.
 */
export const getAllKeys = (): string[] => {
    const keys: string[] = [];
    for (let i = 0; i < localStorage.length; i++) {
        const val = localStorage.key(i);
        if (val !== null) {
            keys.push(val);
        }
    }
    return keys;
}
