import React from 'react';
import './Wordle.css';
import * as Types from './Types';
import * as LocalStorage from './LocalStorage';
import * as Score from './Score';
import * as WelcomeDialog from './WelcomeDialog';
import * as Loading from './Loading';

interface WordleState {
    numLetters: number;
    date: string;
    guesses: Types.Guess[];
    currentGuess: string;
    guessWasWrong: boolean;
    waitingForResponse: boolean;
    scoreInformation: Types.ScoreInformation | undefined;
    showWelcomeDialog: boolean;
    showScoreDialog: boolean;
    yesterdaysWord: string | undefined;
}

class Wordle extends React.Component {
    state: WordleState = {
        numLetters: 0,
        date: "",
        guesses: [],
        currentGuess: "",
        guessWasWrong: false,
        waitingForResponse: false,
        scoreInformation: undefined,
        showWelcomeDialog: false,
        showScoreDialog: false,
        yesterdaysWord: undefined,
    }
    componentDidMount(): void {
        const seenWelcomeDialog = WelcomeDialog.readWelcomeDialogSeen();
        this.setState({
            showWelcomeDialog: !seenWelcomeDialog,
        });
        window.addEventListener('keydown', this.physicalKeyboardKeyPress);
        this.initializeComponent();
    }
    initializeComponent(): void {
        this.setState({
                numLetters: 0,
                date: "",
                guesses: [],
                currentGuess: "",
                guessWasWrong: false,
                waitingForResponse: false,
                scoreInformation: undefined,
                showWelcomeDialog: false,
                showScoreDialog: false,
                yesterdaysWord: undefined,
        });
        const now = new Date();
        const nextDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1, 0, 10+getRandomInt(6), getRandomInt(60));
        const diff = nextDay.valueOf() - now.valueOf();
        setTimeout(() => this.initializeComponent(), diff);
        fetch('/api/getNumLetters', {
                headers: {"Cache-Control": "no-cache"}
            })
            .then(response => response.json())
            .then(data => {
                if (typeof data.numLetters === "number" && typeof data.date === "string" &&
                    (data.yesterdaysWord === undefined || typeof data.yesterdaysWord === "string")) {
                    const prevStored: Types.WordleStorage = LocalStorage.getData(data.date) as Types.WordleStorage;
                    if (prevStored === undefined || prevStored.numLetters !== data.numLetters) {
                        const scoreInformation = Score.calcStoredScoreData(true);
                        this.setState({
                            numLetters: data.numLetters,
                            date: data.date,
                            scoreInformation,
                            yesterdaysWord: data?.yesterdaysWord,
                        });
                    } else {
                        const scoreInformation = Score.calcStoredScoreData(false);
                        this.setState({
                            numLetters: data.numLetters,
                            date: data.date,
                            guesses: prevStored.guesses,
                            scoreInformation,
                            yesterdaysWord: data?.yesterdaysWord,
                        });
                    }
                }
            })
            .catch(e => console.log(`Error getNumLetters: ${e}`));
    }

    render(): JSX.Element {
        return (
            <div className="WordleGrid">
                {/*<input type="button" value="Börja om" onClick={() => {LocalStorage.clearAll(); window.location.reload()}}/>*/}
                <div className="TopRow">
                    <ScoreInfo onClick={this.toggleScoreDialog} />
                    {this.state.scoreInformation !== undefined &&
                        <Score.Score scoreInformation={this.state.scoreInformation}
                                     yesterdaysWord={this.state.yesterdaysWord} />}
                    <Help onClick={this.toggleWelcomeDialog} />
                </div>
                <WelcomeDialog.WelcomeDialog show={this.state.showWelcomeDialog} onClose={this.toggleWelcomeDialog}/>
                {this.state.scoreInformation !== undefined &&
                    <Score.ScoreDialog show={this.state.showScoreDialog} onClose={this.toggleScoreDialog} scoreInformation={this.state.scoreInformation}/>}
                <Guesses state={this.state} keyPress={this.physicalKeyboardKeyPress}/>
                <Keyboard guesses={this.state.guesses} keyPress={this.virtualKeyboardKeyPress} />
            </div>
        );
    }

    toggleWelcomeDialog = () => {
        this.setState({
            showWelcomeDialog: !this.state.showWelcomeDialog,
        });
    }

    toggleScoreDialog = () => {
        this.setState({
            showScoreDialog: !this.state.showScoreDialog,
        });
    }

    virtualKeyboardKeyPress = (e: string) => {
        this.handleKeyPress(e);
    }

    physicalKeyboardKeyPress = (e: any) => {
        const c = e.key?.toLowerCase();
        this.handleKeyPress(c);
    }

    handleKeyPress = (key: string | undefined) => {
        if (key === undefined) {
            return;
        }
        const c = key.toLowerCase();
        if (this.state.currentGuess.length < this.state.numLetters) {
            if ("abcdefghijklmnopqrstuvwxyzåäö".indexOf(c) !== -1) {
                const currentGuess = this.state.currentGuess + c;
                this.setState({
                    currentGuess,
                    guessWasWrong: false,
                });
            }
        }
        if (c === "backspace" &&
            !this.state.waitingForResponse) {
            const currentGuess = this.state.currentGuess.slice(0,-1);
            this.setState({
                currentGuess,
                guessWasWrong: false,
            });
        }
        if (c === "enter") {
            if (this.state.currentGuess.length === this.state.numLetters &&
                !this.state.waitingForResponse) {
                this.setState({waitingForResponse: true});
                fetch("/api/guessWord", {
                        method: "POST",
                        headers: {"Content-Type": "application/json",
                                  "Cache-Control": "no-cache" },
                        body: JSON.stringify({guess: this.state.currentGuess}),
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (typeof data.validGuess === "boolean" && data.validGuess === true) {
                            if (Array.isArray(data.letterFeedback) && data.letterFeedback.length === this.state.numLetters) {
                                const guesses = this.state.guesses.slice();
                                const newGuess = {
                                    validGuess: data.validGuess,
                                    letterFeedback: data.letterFeedback,
                                    word: this.state.currentGuess,
                                }
                                guesses.push(newGuess);
                                let scoreInformation = this.state.scoreInformation;
                                if (scoreInformation === undefined) {
                                    scoreInformation = { currentStreak: 0
                                                       , longestStreak: 0
                                                       , daysPlayed: 0
                                                       , daysCorrect: 0
                                                       , daysIncorrect: 0
                                                       , daysNotFinished: 0
                                                       }
                                }
                                const solved = isSolved(guesses);
                                if (guesses.length === Types.numGuesses && !solved) {
                                    if (scoreInformation.currentStreak > 0) {
                                        scoreInformation.currentStreak = 0;
                                    } else {
                                        scoreInformation.currentStreak -= 1;
                                    }
                                    scoreInformation.daysPlayed += 1;
                                    scoreInformation.daysIncorrect += 1;
                                } else if (solved) {
                                    if (scoreInformation.currentStreak >= 0) {
                                        scoreInformation.currentStreak += 1;
                                    } else {
                                        scoreInformation.currentStreak = 1;
                                    }
                                    scoreInformation.daysPlayed += 1;
                                    scoreInformation.daysCorrect += 1;
                                    if (scoreInformation.currentStreak > scoreInformation.longestStreak) {
                                        scoreInformation.longestStreak = scoreInformation.currentStreak;
                                    }
                                }
                                this.setState({
                                    guesses,
                                    currentGuess: "",
                                    waitingForResponse: false,
                                    scoreInformation,
                                });
                                const toStore: Types.WordleStorage = {
                                    numLetters: this.state.numLetters,
                                    guesses,
                                }
                                LocalStorage.setData(this.state.date, toStore);
                            }
                        } else {
                            this.setState({
                                guessWasWrong: true,
                                waitingForResponse: false,
                            });
                        }
                    })
                    .catch(e => console.log(`Error guessWord: ${e}`));
            }
        }
    }
}

interface GuessesProps {
    state: WordleState;
    keyPress: any;
}

const Guesses = (props: GuessesProps): JSX.Element => {
    const s = props.state;
    const isSolved = s.guesses.length > 0 && s.guesses[s.guesses.length - 1].letterFeedback.every( (f) => {
        return f === "CorrectPlace";
    });
    const guessesLeft = s.guesses.length < Types.numGuesses && !isSolved;
    if (s.numLetters === 0) {
        return (
            <Loading.Loading/>
        );
    } else {
        return (
            <div className="Guesses">
                {s.guesses.map( (g:Types.Guess, ix:number) => {
                    return (
                        <Guess key={ix} guess={g}/>
                    );
                })}
            {guessesLeft && <CurrentGuess key={`guessesLeft${guessesLeft}`} currentGuess={s.currentGuess} numLetters={s.numLetters} guessWasWrong={s.guessWasWrong} waitingForResponse={s.waitingForResponse} keyPress={props.keyPress}/>}
            </div>
        );
    }
}

interface GuessProps {
    guess: Types.Guess;
}

const Guess = (props: GuessProps): JSX.Element => {
    const g = props.guess;
    return (
        <div className="Word">
            {g.letterFeedback.map( (f: Types.Feedback, i) => {
                const word = g.word[i] ?? "?";
                const classFeedback = f;
                return (
                    <div key={i} className={`Letter ${classFeedback}`}>{word.toUpperCase()}</div>
                );
            })}
        </div>
    );
}

interface CurrentGuessProps {
    currentGuess: string;
    numLetters: number;
    guessWasWrong: boolean;
    waitingForResponse: boolean;
    keyPress: any;
}

const CurrentGuess = (props: CurrentGuessProps): JSX.Element => {
    const wordPadded = props.currentGuess.split("");
    for (let i = wordPadded.length; i < props.numLetters; i++) {
        wordPadded.push("");
    }
    const waitingForResponse = props.waitingForResponse ? "WaitingForResponse" : "";
    const showInvalidGuess = props.guessWasWrong ? "ShowInvalidGuess" : "NotShowInvalidGuess";
    return (
        <div className="Word InvalidGuess">
            {wordPadded.map( (char: string, ix:number) => {
                return (
                    <div key={ix} className={`Letter ${waitingForResponse}`}>
                        <textarea value={props.currentGuess} onInput={props.keyPress} autoComplete="off" autoFocus={true} autoCorrect="off" autoCapitalize="off" spellCheck="false"></textarea>
                        {char.toUpperCase()}
                    </div>
                );
            })}
            <div className="ShowInvalidGuessWrap">
                <div className={showInvalidGuess}>
                Ej i ordlistan!
                </div>
            </div>
        </div>
    );
}

interface KeyboardProps {
    guesses: Types.Guess[];
    keyPress: any;
}
const Keyboard = (props: KeyboardProps) => {
    const g = props.guesses;
    const layout = ["qwertyuiopå", "asdfghjklöä", "  zxcvbnm"];
    const letterRes = letterResults(g);
    return (
        <div className="KeyboardGridWrapper">
            <div className="KeyboardButtonFlex">
                <input type="button" value="Gissa" className="Button GuessButton" onClick={() => props.keyPress("enter")}/>
                <input type="button" value="Ta bort" className="Button RemoveButton" onClick={() => props.keyPress("backspace")}/>
            </div>
            <div className="KeyboardGrid">
                {layout.map( (l) => {
                    return l.split("").map((x:string, ix:number) => {
                        const letterCss = `Keyboard--${letterRes[x]}` ?? "";
                        if (x === " ") {
                            return <div key={`${x}${ix}`}></div>
                        } else {
                            return (
                                <div key={x} className={`KeyboardKey ${letterCss}`} onClick={() => props.keyPress(x)}>{x.toUpperCase()}</div>
                            );
                        }
                    })
                })}
            </div>
        </div>
    );
}

const letterResults = (guesses: Types.Guess[]): {[key: string]: string} => {
    const res: {[key: string]: string} = {};
    guesses.forEach( (g: Types.Guess) => {
        const wordArr = g.word.split("");
        g.letterFeedback.forEach( (feedback: Types.Feedback, i: number) => {
            const c = wordArr[i];
            if (feedback === "CorrectPlace") {
                res[c] = feedback;
            } else if (feedback === "LetterExist") {
                if (!res[c]) { // Check if already entered.
                    res[c] = feedback;
                }
            } else if (feedback === "LetterNotExist") {
                if (!res[c]) { // Check if already entered.
                    res[c] = feedback;
                }
            }
        });
    });
    return res;
}

const isSolved = (guesses: Types.Guess[]) => {
    const solved = guesses.length > 0 && guesses[guesses.length - 1].letterFeedback.every( (f) => {
        return f === "CorrectPlace";
    });
    return solved;
}

interface HelpProps {
    onClick: any;
}

const Help = (props: HelpProps):JSX.Element => {
    return (
        <div className="InfoButton" onClick={() => props.onClick()}>
            ?
        </div>
    );
}

interface ScoreInfoProps {
    onClick: any;
}

const ScoreInfo = (props: ScoreInfoProps): JSX.Element => {
    return (
        <div className="InfoButton ScoreInfo" onClick={() => props.onClick()}>
            !
        </div>
    );
}

/**
 * Get a random integer.
 * Range: [0, max)
 */
const getRandomInt = (max: number):number => {
  return Math.floor(Math.random() * max);
}

export default Wordle;
