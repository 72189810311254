import './Loading.css';

export const Loading = (): JSX.Element => {
    return (
        <div className="Loading">
            <span className="LoadingAnimation LoadingRed">L</span>
            <span className="LoadingAnimation LoadingOrange">a</span>
            <span className="LoadingAnimation LoadingYellow">d</span>
            <span className="LoadingAnimation LoadingGreen">d</span>
            <span className="LoadingAnimation LoadingBlue">a</span>
            <span className="LoadingAnimation LoadingIndigo">r</span>
            <span className="LoadingAnimation LoadingViolet">.</span>
            <span className="LoadingAnimation LoadingPink">.</span>
            <span className="LoadingAnimation LoadingCyan">.</span>
        </div>
    );
}
