export type Feedback = "CorrectPlace" | "LetterExist" | "LetterNotExist";

export interface Guess {
    word: string;
    letterFeedback: Feedback[];
    validGuess: boolean;
}

export interface WordleStorage {
    numLetters: number;
    guesses: Guess[];
}

export interface ScoreInformation {
    currentStreak: number;
    longestStreak: number;
    daysPlayed: number;
    daysCorrect: number;
    daysIncorrect: number;
    daysNotFinished: number;
}

export const numGuesses = 6;
