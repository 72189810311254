import React from 'react';
import './WelcomeDialog.css';
import * as LocalStorage from './LocalStorage';

export const localStorageKey = "WelcomeDialogSeen"

/**
 * Read the stored value from local storage if the
 * welcome dialog has been seen.
 */
export const readWelcomeDialogSeen = (): boolean => {
    const seen = LocalStorage.getData(localStorageKey);
    if (seen) {
        return true;
    } else {
        return false;
    }
}

/**
 * Store that the dialog has been seen in local storage.
 */
const storeDialogSeen = () => {
    LocalStorage.setData(localStorageKey, true);
}

interface WelcomeDialogProps {
    show: boolean;
    onClose: any;
}

/**
 * Shows a welcome dialog which explains how to play the game.
 */
export const WelcomeDialog = (props: WelcomeDialogProps): JSX.Element => {
    if (props.show) {
        return (
            <dialog open>
                <DialogContent onClose={props.onClose}/>
            </dialog>
        );
    } else {
        return (
            <dialog>
            </dialog>
        );
    }
}

interface DialogContentProps {
    onClose: any;
}

const DialogContent = (props: DialogContentProps): JSX.Element => {
    return (
        <div>
            <h1>Wordle - På Svenska</h1>
            <p>Gissa ordet på <strong>6</strong> gissningar.</p>
            <p>Efter varje gissning visas hur nära gissningen var.</p>
            <h2>Exempel</h2>
                <div className="Word">
                    <div className={`Letter CorrectPlace`}>B</div>
                    <div className={`Letter`}>U</div>
                    <div className={`Letter`}>L</div>
                    <div className={`Letter`}>T</div>
                </div>
                <p>Bokstaven <strong>B</strong> är med i ordet och på rätt plats.</p>
                <div className="Word">
                    <div className={`Letter`}>K</div>
                    <div className={`Letter LetterExist`}>A</div>
                    <div className={`Letter`}>N</div>
                    <div className={`Letter`}>T</div>
                </div>
                <p>Bokstaven <strong>A</strong> är med i ordet, men på fel plats.</p>
                <div className="Word">
                    <div className={`Letter`}>N</div>
                    <div className={`Letter`}>E</div>
                    <div className={`Letter`}>R</div>
                    <div className={`Letter LetterNotExist`}>V</div>
                </div>
                <p>Bokstaven <strong>V</strong> är inte med i ordet.</p>
            <hr/>
            <p>Ett nytt ord kommer varje dag!</p>
            <form method="dialog">
                <button className="Button" onClick={() => {storeDialogSeen(); props.onClose()}}>Stäng</button>
            </form>
        </div>
    );
}
